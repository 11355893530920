import https from "./https"
import store from "@/store"

const pushMessageTemplate = {
  getPushMessageTemplates(params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/push-message-templates`, { params })
  },

  createPushMessageTemplate(params) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/push-message-templates`, params)
  },

  getPushMessageTemplate(pushMessageTemplate) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/push-message-templates/${pushMessageTemplate}`)
  },

  updatePushMessageTemplate(pushMessageTemplate, params) {
    const organization = store.state.general.organization
    return https.put(`admin/organizations/${organization.id}/push-message-templates/${pushMessageTemplate}`, params)
  },
  removePushMessageTemplate(pushMessageTemplate, params) {
    const organization = store.state.general.organization
    return https.delete(`admin/organizations/${organization.id}/push-message-templates/${pushMessageTemplate}`, {params})
  },
  uploadImage(file) {
    return https.post(`general/images/upload`, file)
  },
  publishPushMessageTemplate(pushMessageTemplate) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/push-message-templates/${pushMessageTemplate}/publish`)
  },


}

export default pushMessageTemplate
