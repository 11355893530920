import PermissionChecker from "../utils/PermissionChecker";
import * as consts from "@/consts";

export default {
  data() {
    return {
      consts,
    };
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
